/* eslint-disable no-underscore-dangle */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useState } from "react";
import {
  CCreateElement,
  CSidebar,
  CSidebarNav,
  CSidebarNavTitle,
  CSidebarMinimizer,
  CSidebarNavDropdown,
  CSidebarNavItem,
  CSidebarFooter,
  CSidebarBrand,
  CImg,
  CRow,
  CCol,
} from "@coreui/react";
import { useSelector } from "react-redux";
import { selectIsSidebarOpen, selectUserRights, toggleSidebar } from "store/userSlice";
import { useAppDispatch } from "store";
import RoutesEnum, { authorizedRoutes, availableRoutes } from "constants/PageRoutes";
import { selectLogo } from "store/brandingSlice";
import packageJson from "../../../package.json";
import paLogo from "../../assets/logo192.png";
import { useConfigurations } from "hooks/useConfigurations";
import ConfigurationKeyEnum from "constants/ConfigurationKey";

const appVersion = packageJson.version;

interface NavItem {
  _tag: string;
  name?: string;
  to?: string;
  configurationKey?: ConfigurationKeyEnum;
  disabledForConfigurationKey?: boolean;
  icon: string;
  _children?: NavItem[];
}

const navigation: NavItem[] = [
  {
    _tag: "CSidebarNavDropdown",
    name: "Administracija",
    icon: "cil-bank",
    _children: [
      {
        _tag: "CSidebarNavItem",
        name: availableRoutes.get(RoutesEnum.ClassificationCodes)?.pageTitle,
        to: `${RoutesEnum.ClassificationCodes}`,
        icon: "cil-bookmark",
      },
      {
        _tag: "CSidebarNavItem",
        name: availableRoutes.get(RoutesEnum.Departments)?.pageTitle,
        to: `${RoutesEnum.Departments}`,
        icon: "cil-building",
      },
      {
        _tag: "CSidebarNavItem",
        name: availableRoutes.get(RoutesEnum.Users)?.pageTitle,
        to: `${RoutesEnum.Users}`,
        icon: "cil-people",
      },
      {
        _tag: "CSidebarNavItem",
        name: availableRoutes.get(RoutesEnum.Contractors)?.pageTitle,
        to: `${RoutesEnum.Contractors}`,
        icon: "cil-briefcase",
      },
      {
        _tag: "CSidebarNavItem",
        name: availableRoutes.get(RoutesEnum.NkdClasses)?.pageTitle,
        to: `${RoutesEnum.NkdClasses}`,
        icon: "cil-industry",
      },
      {
        _tag: "CSidebarNavItem",
        name: availableRoutes.get(RoutesEnum.ContractorClasses)?.pageTitle,
        to: `${RoutesEnum.ContractorClasses}`,
        icon: "cil-library",
      },
      {
        _tag: "CSidebarNavItem",
        name: availableRoutes.get(RoutesEnum.OfficialNoteTexts)?.pageTitle,
        to: `${RoutesEnum.OfficialNoteTexts}`,
        icon: "cil-library",
      },
      {
        _tag: "CSidebarNavItem",
        name: availableRoutes.get(RoutesEnum.ManagementBooks)?.pageTitle,
        to: `${RoutesEnum.ManagementBooks}`,
        icon: "cil-folder",
        configurationKey: ConfigurationKeyEnum.ManagementBooks,
      },
    ],
  },
  {
    _tag: "CSidebarNavDropdown",
    name: "Predmeti",
    icon: "cil-book",
    _children: [
      {
        _tag: "CSidebarNavItem",
        name: availableRoutes.get(RoutesEnum.AdministrativeDocumentTypes)?.pageTitle,
        to: `${RoutesEnum.AdministrativeDocumentTypes}`,
        icon: "cil-library-add",
      },
      {
        _tag: "CSidebarNavItem",
        name: availableRoutes.get(RoutesEnum.AdministrativeCases)?.pageTitle,
        to: `${RoutesEnum.AdministrativeCases}`,
        icon: "cil-folder",
      },
      {
        _tag: "CSidebarNavItem",
        name: availableRoutes.get(RoutesEnum.AssignedAdministrativeCases)?.pageTitle,
        to: `${RoutesEnum.AssignedAdministrativeCases}`,
        icon: "cil-folder",
      },
      {
        _tag: "CSidebarNavItem",
        name: availableRoutes.get(RoutesEnum.AdministrativeCasesAssign)?.pageTitle,
        to: `${RoutesEnum.AdministrativeCasesAssign}`,
        icon: "cil-folder-open",
      },
      {
        _tag: "CSidebarNavItem",
        name: availableRoutes.get(RoutesEnum.AdministrativeCasesAssignedToDepartment)?.pageTitle,
        to: `${RoutesEnum.AdministrativeCasesAssignedToDepartment}`,
        icon: "cil-folder",
        configurationKey: ConfigurationKeyEnum.AdministrativeCaseAssignToDepartment,
      },
      {
        _tag: "CSidebarNavItem",
        name: availableRoutes.get(RoutesEnum.AdministrativeCasesPlanner)?.pageTitle,
        to: `${RoutesEnum.AdministrativeCasesPlanner}`,
        icon: "cil-book",
      },
      {
        _tag: "CSidebarNavItem",
        name: availableRoutes.get(RoutesEnum.AdministrativeDocumentsRegistryBook)?.pageTitle,
        to: `${RoutesEnum.AdministrativeDocumentsRegistryBook}`,
        icon: "cil-notes",
      },
      {
        _tag: "CSidebarNavItem",
        name: availableRoutes.get(RoutesEnum.DocumentHeaders)?.pageTitle,
        to: `${RoutesEnum.DocumentHeaders}`,
        icon: "cil-file",
      },
      {
        _tag: "CSidebarNavItem",
        name: availableRoutes.get(RoutesEnum.OrderForms)?.pageTitle,
        to: `${RoutesEnum.OrderForms}`,
        icon: "cil-file",
        configurationKey: ConfigurationKeyEnum.OrderForms,
      },
      {
        _tag: "CSidebarNavItem",
        name: availableRoutes.get(RoutesEnum.DocumentSign)?.pageTitle,
        to: `${RoutesEnum.DocumentSign}`,
        icon: "cil-pen-alt",
      },
    ],
  },
  {
    _tag: "CSidebarNavDropdown",
    name: "Arhiva",
    icon: "cil-book",
    _children: [
      {
        _tag: "CSidebarNavItem",
        name: availableRoutes.get(RoutesEnum.AdministrativeCaseArchiveUnits)?.pageTitle,
        to: `${RoutesEnum.AdministrativeCaseArchiveUnits}`,
        icon: "cil-library-add",
      },
      {
        _tag: "CSidebarNavItem",
        name: availableRoutes.get(RoutesEnum.AdministrativeCaseArchiveHistories)?.pageTitle,
        to: `${RoutesEnum.AdministrativeCaseArchiveHistories}`,
        icon: "cil-library-add",
      },
    ],
  },
  {
    _tag: "CSidebarNavDropdown",
    name: "Postavke",
    icon: "cil-settings",
    _children: [
      {
        _tag: "CSidebarNavItem",
        name: availableRoutes.get(RoutesEnum.Configuration)?.pageTitle,
        to: `${RoutesEnum.Configuration}`,
        icon: "cil-applications-settings",
      },
      {
        _tag: "CSidebarNavItem",
        name: availableRoutes.get(RoutesEnum.UserOptions)?.pageTitle,
        to: `${RoutesEnum.UserOptions}`,
        icon: "cil-address-book",
      },
      {
        _tag: "CSidebarNavItem",
        name: availableRoutes.get(RoutesEnum.UserTokens)?.pageTitle,
        to: `${RoutesEnum.UserTokens}`,
        icon: "cil-list",
      },
      {
        _tag: "CSidebarNavItem",
        name: availableRoutes.get(RoutesEnum.EntityChangeLog)?.pageTitle,
        to: `${RoutesEnum.EntityChangeLog}`,
        icon: "cil-list",
      },
      {
        _tag: "CSidebarNavItem",
        name: availableRoutes.get(RoutesEnum.EntityChangeLogPropertiesList)?.pageTitle,
        to: `${RoutesEnum.EntityChangeLogPropertiesList}`,
        icon: "cil-list",
      },
    ],
  },
];

function prepareForHTML(items: NavItem[]) {
  items.forEach((x) => {
    delete x.configurationKey;
    delete x.disabledForConfigurationKey;
    if (x._children) {
      prepareForHTML(x._children);
    }
  });
}

const Sidebar = () => {
  const [minimize, setMinimize] = useState(false);
  const isSidebarOpen = useSelector(selectIsSidebarOpen);
  const userRights = useSelector(selectUserRights);
  const logo = useSelector(selectLogo);
  const dispatch = useAppDispatch();
  const { configurations } = useConfigurations();

  const filterNavigationChildrenByRights = (items: NavItem[]): NavItem[] => {
    return items.filter((item) => {
      const routeName = authorizedRoutes.find((aRoute) => aRoute.routePath === item.to);
      return userRights?.find((right) => right.optionItem === routeName?.key && right.selected);
    });
  };

  const filterNavigationByRights = (items: NavItem[]): NavItem[] => {
    const newItems = items.reduce<NavItem[]>((acc, curr) => {
      if (!curr._children) {
        acc.push(curr);
      } else {
        const newChildren = filterNavigationChildrenByRights(curr._children);
        if (newChildren.length > 0) {
          const newCurrent = { ...curr, _children: newChildren };
          acc.push(newCurrent);
        }
      }
      return acc;
    }, []);
    return newItems;
  };

  const filterNavigationByConfiguration = (items: NavItem[]): NavItem[] => {
    const newItems = items.reduce<NavItem[]>((acc, curr) => {
      if (!curr._children) {
        if (!curr.configurationKey) {
          acc.push(curr);
        } else {
          const configuration = configurations?.find((x) => curr.configurationKey! === parseInt(x.key));
          const result = configuration ? (JSON.parse(configuration.value) as boolean) : false;
          if (result && !curr.disabledForConfigurationKey) acc.push(curr);
        }
      } else {
        const newChildren = filterNavigationByConfiguration(curr._children);
        if (newChildren.length > 0) {
          const newCurrent = { ...curr, _children: newChildren };
          acc.push(newCurrent);
        }
      }
      return acc;
    }, []);
    return newItems;
  };

  const filterNavigation = (items: NavItem[]): NavItem[] => {
    const filteredItems = filterNavigationByConfiguration(filterNavigationByRights(items));
    prepareForHTML(filteredItems);
    return filteredItems;
  };

  const toggleSidebarMobile = (value: boolean | "responsive") => {
    dispatch(toggleSidebar(value));
  };

  return (
    <CSidebar
      show={isSidebarOpen}
      onMinimizeChange={(value: boolean) => setMinimize(!value)}
      minimize={minimize}
      onShowChange={toggleSidebarMobile}
      style={{ userSelect: "none" }}
      size="lg"
    >
      <CSidebarNav>
        <CSidebarNavTitle className="h5">Izbornik</CSidebarNavTitle>
        <CCreateElement
          items={filterNavigation(navigation)}
          components={{
            CSidebarNavDropdown,
            CSidebarNavItem,
            CSidebarNavTitle,
          }}
        />
      </CSidebarNav>
      <CSidebarFooter className="text-sm-center m-0 p-0">
        <CSidebarBrand to="">
          <CRow className="w-100 align-items-center">
            <CCol>
              <CImg src={logo} alt="Logo" className="m-2" fluid height={42} width={120} shape="rounded" align="right" />
            </CCol>
            <CCol className="text-sm-center">
              <CCol>
                <CImg
                  src={paLogo}
                  className="m-2"
                  fluid
                  height={24}
                  width={24}
                  shape="rounded"
                  align="center"
                  alt="Pseudo-archeion logo"
                />
                <small>
                  <strong className="mx-2">v. {appVersion}</strong>
                </small>
              </CCol>
            </CCol>
          </CRow>
        </CSidebarBrand>
      </CSidebarFooter>
      <CSidebarMinimizer className="c-d-md-down-none" />
    </CSidebar>
  );
};

export default React.memo(Sidebar);
