import axios from "axios";

const http = axios.create({
  baseURL: process.env.REACT_APP_API_ENDPOINT,
});

http.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response?.data) return Promise.reject(error.response.data);
    return Promise.reject(error);
  },
);

export const addAuthorization = (token: string): void => {
  http.defaults.headers.common.Authorization = `Bearer ${token}`;
};

export const removeAuthorization = (): void => {
  http.defaults.headers.common.Authorization = undefined;
};

export default http;
