export enum RoutesEnum {
  LogIn = "/login",
  PageNotFound = "/page-not-found",
  BlankPage = "/",
  PageUserNotAuthorized = "/unauthorized-access",

  ChangePassword = "/settings/change-password",
  EntityChangeLog = "/entity-change-log",
  EntityChangeLogView = "/entity-change-log-details",

  EntityChangeLogPropertiesList = "/entity-change-log-properties-list",

  ManagementBooks = "/management-books",
  ManagementBooksEdit = "/management-books/edit",
  ManagementBooksCreate = "/management-books/create",
  ManagementBooksDelete = "/management-books/delete",

  DocumentHeaders = "/document-headers",
  DocumentHeadersEdit = "/document-headers/edit",
  DocumentHeadersCreate = "/document-headers/create",
  DocumentSign = "/document-headers/sign",

  AdministrativeCases = "/administrative-cases",
  AssignedAdministrativeCases = "/assigned-administrative-cases",
  AdministrativeCasesAssignedToDepartment = "/administrative-cases-assigned-to-department",
  AdministrativeCasesEdit = "/administrative-cases/edit",
  AdministrativeCasesCreate = "/administrative-cases/create",
  AdministrativeCasesView = "/administrative-cases/view",
  AdministrativeCasesDelete = "/administrative-cases/delete",
  AdministrativeCasesAssign = "/administrative-cases-assign",
  AdministrativeCasesPlanner = "/administrative-cases-planner",

  AdministrativeCaseDocumentCreate = "/administrative-case-document/create",
  AdministrativeCaseDocumentCreateFrom = "/administrative-case-document/create-from",
  AdministrativeCaseDocumentEdit = "/administrative-case-document/edit",

  AdministrativeCaseArchiveUnits = "/administrative-case-archive-units",
  AdministrativeCaseArchiveUnitsEdit = "/administrative-case-archive-units/edit",
  AdministrativeCaseArchiveUnitsView = "/administrative-case-archive-units/view",
  AdministrativeCaseArchiveUnitsCreate = "/administrative-case-archive-units/create",
  AdministrativeCaseArchiveUnitsDelete = "/administrative-case-archive-units/delete",

  AdministrativeDocumentCreate = "/administrative-document/create",
  AdministrativeDocumentEdit = "/administrative-document/edit",

  AdministrativeDocumentTypes = "/administrative-document-types",
  AdministrativeDocumentTypeEdit = "/administrative-document-types/edit",
  AdministrativeDocumentTypeCreate = "/administrative-document-types/create",
  AdministrativeDocumentsDelete = "/administrative-documents/delete",
  AdministrativeDocumentsRegistryBook = "/administrative-documents-registry-book",

  ClassificationCodes = "/classification-codes",
  ClassificationCodesEdit = "/classification-codes/edit",
  ClassificationCodesCreate = "/classification-codes/create",
  ClassificationCodesCreateFrom = "/classification-codes/create-from",

  Configuration = "/configurations",

  ContractorClasses = "/contractor-classes",
  ContractorClassesEdit = "/contractor-classes/edit",
  ContractorClassesCreate = "/contractor-classes/create",

  Contractors = "/contractors",
  ContractorsEdit = "/contractors/edit",
  ContractorsCreate = "/contractors/create",

  Departments = "/departments",
  DepartmentsEdit = "/departments/edit",
  DepartmentsCreate = "/departments/create",

  Files = "/files",
  FilesGetForDocumentHeader = "/files/get-for-document-header",

  NkdClasses = "/nkdclasses",
  NkdClassesEdit = "/nkdclasses/edit",
  NkdClassesCreate = "/nkdclasses/create",
  NkdClassesCreateFrom = "/nkdclasses/create-from",

  UserOptions = "/user-options",
  UserOptionsGet = "/get-user-options",

  Users = "/users",
  UsersEdit = "/users/edit",
  UsersCreate = "/users/create",
  UserTokens = "/users/tokens",

  AdministrativeCaseArchiveHistories = "/administrative-case-archive-histories",
  AdministrativeCaseArchiveHistoriesCreate = "/administrative-case-archive-histories/create",
  AdministrativeCaseArchiveHistoriesEdit = "/administrative-case-archive-histories/edit",
  AdministrativeCaseArchiveHistoriesDelete = "/administrative-case-archive-histories/delete",

  OfficialNoteTexts = "/officialNoteTexts",
  OfficialNoteTextsEdit = "/officialNoteTexts/edit",
  OfficialNoteTextsCreate = "/officialNoteTexts/create",
  OfficialNoteTextsDelete = "/officialNoteTexts/delete",

  AdministrativeDocumentPublicView = "/administrative-document-public-view",
  AdministrativeDocumentPublicSearch = "/administrative-document-public-search",

  OrderForms = "/order-forms",
}

export const availableRoutes: ReadonlyMap<string, { pageTitle: string; buttonTitle: string; selectable: boolean }> =
  new Map([
    [RoutesEnum.BlankPage, { pageTitle: "Prazna stranica", buttonTitle: "", selectable: true }],
    [RoutesEnum.LogIn, { pageTitle: "Prijavi korisnika", buttonTitle: "Prijava", selectable: false }],
    [
      RoutesEnum.PageNotFound,
      { pageTitle: "Nepostojeća stranica", buttonTitle: "Nepostojeća stranica", selectable: false },
    ],
    [RoutesEnum.PageUserNotAuthorized, { pageTitle: "Korisnik nema autorizaciju", buttonTitle: "", selectable: false }],
    [RoutesEnum.EntityChangeLog, { pageTitle: "Zapisnik događaja", buttonTitle: "Zapisnik", selectable: false }],
    [
      RoutesEnum.EntityChangeLogView,
      { pageTitle: "Detalji iz zapisnika događaja", buttonTitle: "Detalji", selectable: false },
    ],
    [
      RoutesEnum.EntityChangeLogPropertiesList,
      { pageTitle: "Postavke zapisnika događaja", buttonTitle: "Postavke", selectable: false },
    ],

    [RoutesEnum.ManagementBooks, { pageTitle: "Pregled knjiga", buttonTitle: "Knjige", selectable: false }],
    [RoutesEnum.ManagementBooksEdit, { pageTitle: "Uredi knjigu", buttonTitle: "Uredi", selectable: false }],
    [RoutesEnum.ManagementBooksCreate, { pageTitle: "Dodaj knjigu", buttonTitle: "Dodaj", selectable: false }],
    [RoutesEnum.ManagementBooksDelete, { pageTitle: "Izbriši knjigu", buttonTitle: "Izbriši", selectable: false }],

    [RoutesEnum.ChangePassword, { pageTitle: "Izmijeni lozinku", buttonTitle: "Izmjena lozinke", selectable: false }],

    [RoutesEnum.DocumentHeaders, { pageTitle: "Pregled dokumenata", buttonTitle: "Dokumenti", selectable: true }],
    [RoutesEnum.DocumentHeadersEdit, { pageTitle: "Uredi dokument", buttonTitle: "Uredi", selectable: false }],
    [RoutesEnum.DocumentHeadersCreate, { pageTitle: "Dodaj dokument", buttonTitle: "Dodaj", selectable: false }],

    [RoutesEnum.AdministrativeCases, { pageTitle: "Pregled svih predmeta", buttonTitle: "Predmeti", selectable: true }],
    [RoutesEnum.AdministrativeCasesPlanner, { pageTitle: "Rokovnik", buttonTitle: "Predmeti", selectable: true }],

    [
      RoutesEnum.AssignedAdministrativeCases,
      { pageTitle: "Pregled zaduženih predmeta", buttonTitle: "Predmeti", selectable: true },
    ],
    [
      RoutesEnum.AdministrativeCasesAssignedToDepartment,
      {
        pageTitle: "Pregled zaduženih predmeta ustr. jed.",
        buttonTitle: "Spremi",
        selectable: true,
      },
    ],
    [RoutesEnum.AdministrativeCasesEdit, { pageTitle: "Uredi predmet", buttonTitle: "Uredi", selectable: false }],
    [RoutesEnum.AdministrativeCasesDelete, { pageTitle: "Izbriši predmet", buttonTitle: "Izbriši", selectable: false }],
    [RoutesEnum.AdministrativeCasesCreate, { pageTitle: "Dodaj predmet", buttonTitle: "Dodaj", selectable: false }],
    [
      RoutesEnum.AdministrativeCasesView,
      { pageTitle: "Pregledaj predmet", buttonTitle: "Pregledaj", selectable: false },
    ],
    [
      RoutesEnum.AdministrativeCasesAssign,
      { pageTitle: "Pregled nedodijeljenih predmeta", buttonTitle: "Spremi", selectable: true },
    ],
    [
      RoutesEnum.AdministrativeCaseDocumentCreate,
      { pageTitle: "Dodaj predmet i dokument", buttonTitle: "Dodaj", selectable: false },
    ],
    [
      RoutesEnum.AdministrativeCaseDocumentCreateFrom,
      { pageTitle: "Dodaj predmet", buttonTitle: "Kopiraj", selectable: false },
    ],
    [
      RoutesEnum.AdministrativeCaseDocumentEdit,
      { pageTitle: "Uredi predmet i dokument", buttonTitle: "Uredi", selectable: false },
    ],
    [
      RoutesEnum.AdministrativeDocumentsRegistryBook,
      { pageTitle: "Urudžbeni zapisnik", buttonTitle: "Dokumenti", selectable: true },
    ],

    [
      RoutesEnum.AdministrativeCaseArchiveUnits,
      {
        pageTitle: "Arhivske jedinice predmeta",
        buttonTitle: "Arhivske jedinice",
        selectable: true,
      },
    ],
    [
      RoutesEnum.AdministrativeCaseArchiveUnitsEdit,
      {
        pageTitle: "Uredi arhivsku jedinicu",
        buttonTitle: "Uredi",
        selectable: false,
      },
    ],
    [
      RoutesEnum.AdministrativeCaseArchiveUnitsView,
      {
        pageTitle: "Pregledaj arhivsku jedinicu",
        buttonTitle: "Uredi",
        selectable: false,
      },
    ],
    [
      RoutesEnum.AdministrativeCaseArchiveUnitsCreate,
      { pageTitle: "Dodaj arhivsku jedinicu", buttonTitle: "Dodaj", selectable: false },
    ],
    [
      RoutesEnum.AdministrativeCaseArchiveUnitsDelete,
      { pageTitle: "Izbriši arhivsku jedinicu", buttonTitle: "Izbriši", selectable: false },
    ],

    [
      RoutesEnum.AdministrativeDocumentsDelete,
      { pageTitle: "Izbriši dokument", buttonTitle: "Izbriši", selectable: false },
    ],
    [RoutesEnum.AdministrativeDocumentCreate, { pageTitle: "Dodaj dokument", buttonTitle: "Dodaj", selectable: false }],
    [RoutesEnum.AdministrativeDocumentEdit, { pageTitle: "Uredi dokument", buttonTitle: "Uredi", selectable: false }],

    [
      RoutesEnum.AdministrativeDocumentTypes,
      { pageTitle: "Pregled tipova dokumenta", buttonTitle: "Tipovi", selectable: true },
    ],
    [
      RoutesEnum.AdministrativeDocumentTypeEdit,
      { pageTitle: "Uredi tip dokumenta", buttonTitle: "Uredi", selectable: false },
    ],
    [
      RoutesEnum.AdministrativeDocumentTypeCreate,
      { pageTitle: "Dodaj tip dokumenta", buttonTitle: "Dodaj", selectable: false },
    ],

    [
      RoutesEnum.ClassificationCodes,
      { pageTitle: "Klasifikacijske oznake", buttonTitle: "Klasifikacijske oznake", selectable: true },
    ],
    [
      RoutesEnum.ClassificationCodesEdit,
      { pageTitle: "Uredi klasifikacijsku oznaku", buttonTitle: "Uredi", selectable: false },
    ],
    [
      RoutesEnum.ClassificationCodesCreate,
      { pageTitle: "Dodaj klasifikacijsku oznaku", buttonTitle: "Dodaj", selectable: false },
    ],
    [
      RoutesEnum.ClassificationCodesCreateFrom,
      { pageTitle: "Dodaj klasifikacijsku oznaku", buttonTitle: "Kopiraj", selectable: false },
    ],

    [RoutesEnum.Configuration, { pageTitle: "Opće postavke", buttonTitle: "Postavke", selectable: false }],

    [RoutesEnum.ContractorClasses, { pageTitle: "Klasifikacija stranaka", buttonTitle: "Pregled", selectable: true }],
    [
      RoutesEnum.ContractorClassesEdit,
      { pageTitle: "Uredi klasifikaciju stranaka", buttonTitle: "Uredi", selectable: false },
    ],
    [
      RoutesEnum.ContractorClassesCreate,
      { pageTitle: "Dodaj klasifikaciju stranaka", buttonTitle: "Dodaj", selectable: false },
    ],

    [RoutesEnum.Contractors, { pageTitle: "Stranke", buttonTitle: "Stranke", selectable: true }],
    [RoutesEnum.ContractorsEdit, { pageTitle: "Uredi stranku", buttonTitle: "Uredi", selectable: false }],
    [RoutesEnum.ContractorsCreate, { pageTitle: "Dodaj stranku", buttonTitle: "Dodaj", selectable: false }],

    [
      RoutesEnum.Departments,
      { pageTitle: "Ustrojstvene jedinice", buttonTitle: "Ustrojstvene jedinice", selectable: true },
    ],
    [RoutesEnum.DepartmentsEdit, { pageTitle: "Uredi ustrojstvenu jedinicu", buttonTitle: "Uredi", selectable: false }],
    [
      RoutesEnum.DepartmentsCreate,
      { pageTitle: "Dodaj ustrojstvenu jedinicu", buttonTitle: "Dodaj", selectable: false },
    ],

    [
      RoutesEnum.OfficialNoteTexts,
      { pageTitle: "Službene bilješke", buttonTitle: "Službene bilješke", selectable: true },
    ],
    [
      RoutesEnum.OfficialNoteTextsEdit,
      { pageTitle: "Uredi službenu bilješku", buttonTitle: "Uredi", selectable: false },
    ],
    [
      RoutesEnum.OfficialNoteTextsCreate,
      { pageTitle: "Dodaj službenu bilješku", buttonTitle: "Dodaj", selectable: false },
    ],

    [
      RoutesEnum.NkdClasses,
      { pageTitle: "Klasifikacija djelatnosti", buttonTitle: "Klasifikacija djelatnosti", selectable: true },
    ],
    [
      RoutesEnum.NkdClassesEdit,
      { pageTitle: "Uredi klasifikaciju djelatnosti", buttonTitle: "Uredi", selectable: false },
    ],
    [
      RoutesEnum.NkdClassesCreate,
      { pageTitle: "Dodaj klasifikaciju djelatnosti", buttonTitle: "Dodaj", selectable: false },
    ],
    [
      RoutesEnum.NkdClassesCreateFrom,
      { pageTitle: "Dodaj klasifikaciju djelatnosti", buttonTitle: "Kopiraj", selectable: false },
    ],

    [RoutesEnum.UserOptions, { pageTitle: "Korisničke postavke", buttonTitle: "Postavke", selectable: false }],
    [RoutesEnum.UserOptionsGet, { pageTitle: "Korisničke postavke", buttonTitle: "Postavke", selectable: false }],

    [RoutesEnum.Users, { pageTitle: "Korisnici", buttonTitle: "Korisnici", selectable: true }],
    [RoutesEnum.UsersEdit, { pageTitle: "Uredi korisnika", buttonTitle: "Uredi", selectable: false }],
    [RoutesEnum.UsersCreate, { pageTitle: "Dodaj korisnika", buttonTitle: "Dodaj", selectable: false }],
    [RoutesEnum.UserTokens, { pageTitle: "Korisnički tokeni", buttonTitle: "Dodaj", selectable: false }],
    [
      RoutesEnum.AdministrativeCaseArchiveHistories,
      { pageTitle: "Povijest arhiviranja predmeta", buttonTitle: "Povijest", selectable: false },
    ],
    [
      RoutesEnum.AdministrativeCaseArchiveHistoriesCreate,
      { pageTitle: "Dodaj zapis", buttonTitle: "Dodaj", selectable: false },
    ],
    [
      RoutesEnum.AdministrativeCaseArchiveHistoriesEdit,
      { pageTitle: "Uredi zapis", buttonTitle: "Uredi", selectable: false },
    ],
    [
      RoutesEnum.AdministrativeDocumentPublicView,
      {
        pageTitle: "Javni pregled administrativnog dokumenta",
        buttonTitle: "Javni pregled administrativnog dokumenta",
        selectable: false,
      },
    ],
    [
      RoutesEnum.AdministrativeDocumentPublicSearch,
      {
        pageTitle: "Javno pretraživanje administrativnih dokumenata",
        buttonTitle: "Javno pretraživanje administrativnih dokumenata",
        selectable: false,
      },
    ],
    [RoutesEnum.OrderForms, { pageTitle: "Narudžbenice", buttonTitle: "Narudžbenice", selectable: true }],
    [RoutesEnum.DocumentSign, { pageTitle: "e-Potpis dokumenti", buttonTitle: "Dodaj", selectable: true }],
  ]);

export const authorizedRoutes = Object.keys(RoutesEnum).map((key) => {
  const convertedKey = key as keyof typeof RoutesEnum;
  return {
    key: convertedKey,
    routePath: RoutesEnum[convertedKey],
    pageTitle: availableRoutes.get(RoutesEnum[convertedKey])?.pageTitle || "",
    selectable: availableRoutes.get(RoutesEnum[convertedKey])?.selectable || false,
  };
});

export const selectableRoutes = authorizedRoutes.filter((item) => item.selectable);

export default RoutesEnum;
