import { Action, configureStore, ThunkAction } from "@reduxjs/toolkit";
import { useDispatch } from "react-redux";
import { loading } from "./loadingSlice";
import userReducer from "./userSlice";
import brandingReducer from "./brandingSlice";

export const store = configureStore({
  reducer: {
    user: userReducer,
    branding: brandingReducer,
    loading: loading.reducer,
  },
});

export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<ReturnType, RootState, unknown, Action<string>>;
export type AppDispatch = typeof store.dispatch;
export const useAppDispatch = (): AppDispatch => useDispatch<AppDispatch>();
export default store;
