import { CToast, CToastBody, CToaster, CToastHeader } from "@coreui/react";
import React from "react";
import { useSelector } from "react-redux";
import { selectToasts } from "store/userSlice";

const Toaster: React.FC = () => {
  const toasts = useSelector(selectToasts);

  return (
    <CToaster position="top-right">
      {toasts.map((toast, index) => {
        const key = `toast-${index}`;

        return (
          <CToast key={key} show autohide={3000}>
            <CToastHeader className={`bg-${toast.type}`} />
            <CToastBody>{toast.text}</CToastBody>
          </CToast>
        );
      })}
    </CToaster>
  );
};

export default Toaster;
