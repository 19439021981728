import { UserDto } from "models";
import { PaginationResponse } from "models/responses";
import {
  ChangePasswordRequest,
  CreateUserRequest,
  EditUserRequest,
  PaginationRequest,
  ResetPasswordRequest,
} from "models/requests";
import http from "./http";
import loggerApi from "api/loggerApi";

const baseRoute = "users";
const usersApi = {
  async getMe(): Promise<UserDto> {
    try {
      const response = await http.get<UserDto>(`${baseRoute}/me`);
      return response.data;
    } catch (e) {
      loggerApi.log({
        level: "error",
        message: "usersApi.getMe",
        error: e.message || "",
      });
      throw e;
    }
  },

  async get(id: number): Promise<UserDto> {
    try {
      const response = await http.get<UserDto>(`${baseRoute}/get/${id}`);
      return response.data;
    } catch (e) {
      loggerApi.log({
        level: "error",
        message: "usersApi.get",
        error: e.message || "",
      });
      throw e;
    }
  },

  async getAll(): Promise<Array<UserDto>> {
    try {
      const response = await http.get<Array<UserDto>>(`${baseRoute}/get-all`);
      return response.data;
    } catch (e) {
      loggerApi.log({
        level: "error",
        message: "usersApi.getAll",
        error: e.message || "",
      });
      throw e;
    }
  },

  async getUsersByDepartmentId(
    departmentId: number,
    request: PaginationRequest<UserDto>,
  ): Promise<PaginationResponse<Array<UserDto>>> {
    try {
      const response = await http.post<PaginationResponse<Array<UserDto>>>(
        `${baseRoute}/get-paginated-by-departmentId/${departmentId}`,
        request,
      );
      return response.data;
    } catch (e) {
      loggerApi.log({
        level: "error",
        message: "usersApi.getUsersByDepartmentId",
        error: e.message || "",
      });
      throw e;
    }
  },

  async getPaginated(request: PaginationRequest<UserDto>): Promise<PaginationResponse<Array<UserDto>>> {
    try {
      const response = await http.post<PaginationResponse<Array<UserDto>>>(`${baseRoute}/get-paginated`, request);
      return response.data;
    } catch (e) {
      loggerApi.log({
        level: "error",
        message: "usersApi.getPaginated",
        error: e.message || "",
      });
      throw e;
    }
  },

  async update(id: number, request: EditUserRequest): Promise<UserDto> {
    try {
      const response = await http.post(`${baseRoute}/update/${id}`, request);
      return response.data;
    } catch (e) {
      loggerApi.log({
        level: "error",
        message: "usersApi.update",
        error: e.message || "",
      });
      throw e;
    }
  },

  async create(request: CreateUserRequest): Promise<UserDto> {
    try {
      const response = await http.post<UserDto>(`${baseRoute}/create`, request);
      return response.data;
    } catch (e) {
      loggerApi.log({
        level: "error",
        message: "usersApi.create",
        error: e.message || "",
      });
      throw e;
    }
  },

  async delete(id: number): Promise<UserDto> {
    try {
      const response = await http.delete<UserDto>(`${baseRoute}/delete/${id}`);
      return response.data;
    } catch (e) {
      loggerApi.log({
        level: "error",
        message: "usersApi.delete",
        error: e.message || "",
      });
      throw e;
    }
  },

  async changePassword(request: ChangePasswordRequest): Promise<UserDto> {
    try {
      const response = await http.post(`${baseRoute}/change-password`, request);
      return response.data;
    } catch (e) {
      loggerApi.log({
        level: "error",
        message: "usersApi.changePassword",
        error: e.message || "",
      });
      throw e;
    }
  },

  async resetPassword(request: ResetPasswordRequest): Promise<UserDto> {
    try {
      const response = await http.post(`${baseRoute}/reset-password`, request);
      return response.data;
    } catch (e) {
      loggerApi.log({
        level: "error",
        message: "usersApi.resetPassword",
        error: e.message || "",
      });
      throw e;
    }
  },
};

export default usersApi;
