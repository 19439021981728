/* eslint-disable default-case */
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import Router from "router/Router";
import { useAppDispatch } from "store";
import { initializeAsync, logout } from "store/userSlice";
import { initializeBrandingAsync } from "store/brandingSlice";
import http from "api/http";
import "@coreui/coreui/scss/coreui.scss";
import "flatpickr/dist/themes/airbnb.css";
import "./utilities/yupLocalization";
import * as icons from "@coreui/icons";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
(React as any).icons = icons;

const App: React.FC = () => {
  const history = useHistory();
  const dispatch = useAppDispatch();
  const [initialization, setInitialization] = useState<boolean>(true);

  useEffect(() => {
    http.interceptors.response.use(
      (response) => response,
      (error) => {
        if (error.statusCode === 401) {
          dispatch(logout());
          history.replace("/login");
        }
        return Promise.reject(error);
      },
    );

    const initialize = async () => {
      await dispatch(initializeAsync());
      await dispatch(initializeBrandingAsync());
      setInitialization(false);
    };
    initialize();
  }, [dispatch, history]);

  return initialization ? null : <Router />;
};

export default App;
