import { LogRequest } from "models/requests/LogRequest";
import http from "./http";

const baseRoute = "client-logger";

interface HttpStatus {
  status: number;
}

const loggerApi = {
  async log(request: LogRequest): Promise<HttpStatus> {
    return http.post<LogRequest>(`${baseRoute}/log`, request);
  },
};

export default loggerApi;
