import { LoginResponse } from "models/responses";
import { LoginRequest } from "models/requests";
import loggerApi from "api/loggerApi";
import http from "./http";

const authApi = {
  async login(request: LoginRequest): Promise<LoginResponse> {
    try {
      const response = await http.post<LoginResponse>("auth/login", request);
      return response.data;
    } catch (e) {
      loggerApi.log({
        level: "error",
        message: "authApi.login",
        error: e.message || "",
      });
      throw e;
    }
  },
};

export default authApi;
