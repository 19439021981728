import React, { useCallback } from "react";
import Sidebar from "components/layout/Sidebar";
import {
  CHeader,
  CToggler,
  CHeaderNav,
  CDropdownToggle,
  CDropdownMenu,
  CDropdownItem,
  CDropdown,
  CDropdownDivider,
} from "@coreui/react";
import { useAppDispatch } from "store";
import { logout, selectInitials, selectIsSidebarOpen, selectUser, toggleSidebar } from "store/userSlice";
import { useHistory } from "react-router-dom";
import CIcon from "@coreui/icons-react";
import { useSelector } from "react-redux";
import RoutesEnum, { availableRoutes } from "constants/PageRoutes";

const Layout: React.FC = ({ children }) => {
  const dispatch = useAppDispatch();
  const initials = useSelector(selectInitials);
  const history = useHistory();
  const isSidebarOpen = useSelector(selectIsSidebarOpen);
  const currentUser = useSelector(selectUser);

  const handleLogout = useCallback(() => {
    dispatch(logout());
    history.replace("/login");
  }, [history, dispatch]);

  const toggleSidebarDesktop = () => {
    const value = [true, "responsive"].includes(isSidebarOpen) ? false : "responsive";
    dispatch(toggleSidebar(value));
  };

  const toggleSidebarMobile = () => {
    const value = [false, "responsive"].includes(isSidebarOpen) ? true : "responsive";
    dispatch(toggleSidebar(value));
  };

  return (
    <div className="c-app c-default-layout">
      <Sidebar />
      <div className="c-wrapper">
        <CHeader>
          <CToggler inHeader className="ml-md-3 d-lg-none" onClick={toggleSidebarMobile} />
          <CToggler inHeader className="ml-3 d-md-down-none" onClick={toggleSidebarDesktop} />
          <CHeaderNav className="px-3 ml-auto">
            <strong>
              {currentUser?.username} ({currentUser?.firstName} {currentUser?.lastName})
            </strong>
            <CDropdown inNav className="c-header-nav-items mx-2">
              <CDropdownToggle className="c-header-nav-link" caret={false}>
                <div className="c-avatar badge-success font-weight-bold d-flex align-items-center justify-content-center">
                  {initials}
                </div>
              </CDropdownToggle>
              <CDropdownMenu className="pt-0" placement="bottom-end" style={{ margin: 0 }}>
                <CDropdownItem onClick={handleLogout}>
                  <CIcon name="cil-lock-unlocked" />
                  &nbsp;
                  <strong>Odjava</strong>
                </CDropdownItem>
                <CDropdownItem onClick={() => history.push(RoutesEnum.ChangePassword)}>
                  <CIcon name="cil-braille" />
                  &nbsp;
                  <strong>{availableRoutes.get(RoutesEnum.ChangePassword)?.buttonTitle}</strong>
                </CDropdownItem>
                <CDropdownDivider />
                <CDropdownItem onClick={() => history.push(`${RoutesEnum.UsersEdit}/${currentUser?.id}`)}>
                  <CIcon name="cil-user" />
                  &nbsp;
                  <strong>Moj profil</strong>
                </CDropdownItem>
              </CDropdownMenu>
            </CDropdown>
          </CHeaderNav>
        </CHeader>

        {children}
      </div>
    </div>
  );
};

export default Layout;
