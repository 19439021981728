import loggerApi from "api/loggerApi";
import { UserOptionDto } from "models";
import { CreateUserOptionRequest, EditUserOptionRequest } from "models/requests";
import http from "./http";

const baseRoute = "user-options";
const userOptionsApi = {
  async getMyRights(): Promise<UserOptionDto> {
    try {
      const response = await http.get<UserOptionDto>(`${baseRoute}/get-my-rights`);
      return response.data;
    } catch (e) {
      loggerApi.log({
        level: "error",
        message: "userOptionsApi.getMyRights",
        error: e.message || "",
      });
      throw e;
    }
  },

  async getUserOption(userId: number, key: number): Promise<UserOptionDto> {
    try {
      const response = await http.get<UserOptionDto>(`${baseRoute}/get/${userId}/${key}`);
      return response.data;
    } catch (e) {
      loggerApi.log({
        level: "error",
        message: "userOptionsApi.getUserOption",
        error: e.message || "",
      });
      throw e;
    }
  },

  async getUserOptions(userId: number): Promise<Array<UserOptionDto>> {
    try {
      const response = await http.get<Array<UserOptionDto>>(`${baseRoute}/get-all/${userId}`);
      return response.data;
    } catch (e) {
      loggerApi.log({
        level: "error",
        message: "userOptionsApi.getUserOptions",
        error: e.message || "",
      });
      throw e;
    }
  },

  async update(userId: string, key: string, request: EditUserOptionRequest): Promise<UserOptionDto> {
    try {
      const response = await http.post<UserOptionDto>(`${baseRoute}/update/${userId}/${key}`, request);
      return response.data;
    } catch (e) {
      loggerApi.log({
        level: "error",
        message: "userOptionsApi.update",
        error: e.message || "",
      });
      throw e;
    }
  },

  async updateAll(userId: string, request: Array<CreateUserOptionRequest>): Promise<Array<UserOptionDto>> {
    try {
      const response = await http.post<Array<UserOptionDto>>(`${baseRoute}/update-all/${userId}`, request);
      return response.data;
    } catch (e) {
      loggerApi.log({
        level: "error",
        message: "userOptionsApi.updateAll",
        error: e.message || "",
      });
      throw e;
    }
  },

  async create(request: CreateUserOptionRequest): Promise<UserOptionDto> {
    try {
      const response = await http.post<UserOptionDto>(`${baseRoute}/create`, request);
      return response.data;
    } catch (e) {
      loggerApi.log({
        level: "error",
        message: "userOptionsApi.create",
        error: e.message || "",
      });
      throw e;
    }
  },
};

export default userOptionsApi;
