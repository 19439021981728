import React, { lazy, Suspense } from "react";
import { Route, Switch } from "react-router-dom";
import PrivateRoute from "router/PrivateRoute";
import Layout from "components/layout/Layout";
import RoutesEnum from "constants/PageRoutes";
import Loader from "components/shared/Loader";

const LoginPage = lazy(() => import("pages/LoginPage"));
const Page404 = lazy(() => import("pages/Page404"));

const AdministrativeCasesPage = lazy(() => import("pages/administrativeCasePages/AdministrativeCasesPage"));
const CreateAdministrativeCasePage = lazy(() => import("pages/administrativeCasePages/CreateAdministrativeCasePage"));
const EditAdministrativeCasePage = lazy(() => import("pages/administrativeCasePages/EditAdministrativeCasePage"));
const ViewAdministrativeCasePage = lazy(() => import("pages/administrativeCasePages/ViewAdministrativeCasePage"));
const AssignAdministrativeCasesPage = lazy(() => import("pages/administrativeCasePages/AssignAdministrativeCasesPage"));
const AdministrativeCasesAssignedToDepartment = lazy(
  () => import("pages/administrativeCasePages/AdministrativeCasesAssignedToDepartmentPage"),
);
const AdministrativeCasesPlannerPage = lazy(
  () => import("pages/administrativeCasePages/AdministrativeCasesPlannerPage"),
);

const CreateAdministrativeCaseDocumentPage = lazy(
  () => import("pages/administrativeCaseDocumentPages/CreateAdministrativeCaseDocumentPage"),
);
const EditAdministrativeCaseDocumentPage = lazy(
  () => import("pages/administrativeCaseDocumentPages/EditAdministrativeCaseDocumentPage"),
);

const AdministrativeCaseArchiveUnitsPage = lazy(
  () => import("pages/administrativeCaseArchiveUnitPages/AdministrativeCaseArchiveUnitsPage"),
);
const CreateAdministrativeCaseArchiveUnitPage = lazy(
  () => import("pages/administrativeCaseArchiveUnitPages/CreateAdministrativeCaseArchiveUnitPage"),
);
const EditAdministrativeCaseArchiveUnitPage = lazy(
  () => import("pages/administrativeCaseArchiveUnitPages/EditAdministrativeCaseArchiveUnitPage"),
);
const ViewAdministrativeCaseArchiveUnitPage = lazy(
  () => import("pages/administrativeCaseArchiveUnitPages/ViewAdministrativeCaseArchiveUnitPage"),
);

const AdministrativeDocumentTypePage = lazy(
  () => import("pages/administrativeDocumentTypePages/AdministrativeDocumentTypesPage"),
);
const CreateAdministrativeDocumentTypePage = lazy(
  () => import("pages/administrativeDocumentTypePages/CreateAdministrativeDocumentTypePage"),
);
const EditAdministrativeDocumentTypePage = lazy(
  () => import("pages/administrativeDocumentTypePages/EditAdministrativeDocumentTypePage"),
);
const AdministrativeDocumentsRegistryBookPage = lazy(
  () => import("pages/administrativeDocumentPages/AdministrativeDocumentsRegistryBookPage"),
);

const DepartmentsPage = lazy(() => import("pages/departmentPages/DepartmentsPage"));
const CreateDepartmentPage = lazy(() => import("pages/departmentPages/CreateDepartmentPage"));
const EditDepartmentPage = lazy(() => import("pages/departmentPages/EditDepartmentPage"));

const OfficialNoteTextPage = lazy(() => import("pages/officialNoteTextPages/OfficialNoteTextsPage"));
const CreateOfficialNoteTextPage = lazy(() => import("pages/officialNoteTextPages/CreateOfficialNoteTextPage"));
const EditOfficialNoteTextPage = lazy(() => import("pages/officialNoteTextPages/EditOfficialNoteTextPage"));

const UsersPage = lazy(() => import("pages/userPages/UsersPage"));
const CreateUserPage = lazy(() => import("pages/userPages/CreateUserPage"));
const EditUserPage = lazy(() => import("pages/userPages/EditUserPage"));

const ClassificationCodesPage = lazy(() => import("pages/classificationCodePages/ClassificationCodesPage"));
const CreateClassificationCodePage = lazy(() => import("pages/classificationCodePages/CreateClassificationCodePage"));
const EditClassificationCodePage = lazy(() => import("pages/classificationCodePages/EditClassificationCodePage"));

const NkdClassesPage = lazy(() => import("pages/nkdClassPages/NkdClassesPage"));
const CreateNkdClassPage = lazy(() => import("pages/nkdClassPages/CreateNkdClassPage"));
const EditNkdClassPage = lazy(() => import("pages/nkdClassPages/EditNkdClassPage"));

const ContractorsPage = lazy(() => import("pages/contractorPages/ContractorsPage"));
const CreateContractorPage = lazy(() => import("pages/contractorPages/CreateContractorPage"));
const EditContractorPage = lazy(() => import("pages/contractorPages/EditContractorPage"));

const ContractorClassPage = lazy(() => import("pages/contractorClassPages/ContractorClassPage"));
const CreateContractorClassPage = lazy(() => import("pages/contractorClassPages/CreateContractorClassPage"));
const EditContractorClassPage = lazy(() => import("pages/contractorClassPages/EditContractorClassPage"));

const ConfigurationsDataPage = lazy(() => import("pages/configurationPages/ConfigurationsDataPage"));
const UserOptionsPage = lazy(() => import("pages/userOptionPages/UserOptionsPage"));

const CreateAdministrativeDocumentPage = lazy(
  () => import("pages/administrativeDocumentPages/CreateAdministrativeDocumentPage"),
);
const EditAdministrativeDocumentPage = lazy(
  () => import("pages/administrativeDocumentPages/EditAdministrativeDocumentPage"),
);

const AdministrativeCaseArchiveHistoryPage = lazy(
  () => import("pages/administrativeCaseArchiveHistory/AdministrativeCaseArchiveHistoryPage"),
);
const CreateAdministrativeCaseArchiveHistoryPage = lazy(
  () => import("pages/administrativeCaseArchiveHistory/CreateAdministrativeCaseArchiveHistoryPage"),
);
const EditAdministrativeCaseArchiveHistoryPage = lazy(
  () => import("pages/administrativeCaseArchiveHistory/EditAdministrativeCaseArchiveHistoryPage"),
);
const DocumentHeadersPage = lazy(() => import("pages/documentHeadersPages/DocumentHeadersPage"));
const EditDocumentHeaderPage = lazy(() => import("pages/documentHeadersPages/EditDocumentHeaderPage"));
const CreateDocumentHeaderPage = lazy(() => import("pages/documentHeadersPages/CreateDocumentHeaderPage"));

const SigningDocumentHeadersPage = lazy(() => import("pages/documentSign/SigningDocumentHeadersPage"));

const ManagementBookPage = lazy(() => import("pages/managementBookPages/ManagementBooksPage"));
const CreateManagementBookPage = lazy(() => import("pages/managementBookPages/CreateManagementBookPage"));
const EditManagementBookPage = lazy(() => import("pages/managementBookPages/EditManagementBookPage"));

const EntityChangeLogPage = lazy(() => import("pages/entityChangeLogPages/EntityChangeLogPage"));
const ChangePasswordPage = lazy(() => import("pages/userPages/ChangePasswordPage"));
const EntityChangeLogViewPage = lazy(() => import("pages/entityChangeLogPages/EntityChangeLogViewPage"));
const EntityChangeLogPropertiesListPage = lazy(
  () => import("pages/entityChangeLogPages/EntityChangeLogPropertiesListPage"),
);
const PageUserNotAuthorized = lazy(() => import("pages/PageUserNotAuthorized"));

const UserTokensPage = lazy(() => import("pages/userPages/UserTokensPage"));

const AdministrativeDocumentPublicView = lazy(() => import("pages/publicDirectory/AdministrativeDocumentPublicView"));
const AdministrativeDocumentPublicSearch = lazy(
  () => import("pages/publicDirectory/AdministrativeDocumentPublicSearch"),
);

const OrderFormsPage = lazy(() => import("pages/orderForms/OrderFormsPage"));

const Router: React.FC = () => (
  <Switch>
    <PrivateRoute path={RoutesEnum.BlankPage} exact>
      <Layout />
    </PrivateRoute>
    <Route path={RoutesEnum.LogIn}>
      <Suspense fallback={<Loader />}>
        <LoginPage />
      </Suspense>
    </Route>
    <Route path={RoutesEnum.PageNotFound}>
      <Layout>
        <Suspense fallback={<Loader />}>
          <Page404 />
        </Suspense>
      </Layout>
    </Route>
    <Route path={RoutesEnum.PageUserNotAuthorized}>
      <Layout>
        <Suspense fallback={<Loader />}>
          <PageUserNotAuthorized />
        </Suspense>
      </Layout>
    </Route>
    <PrivateRoute exact path={RoutesEnum.ManagementBooksCreate}>
      <Layout>
        <Suspense fallback={<Loader />}>
          <CreateManagementBookPage />
        </Suspense>
      </Layout>
    </PrivateRoute>
    <PrivateRoute exact path={`${RoutesEnum.ManagementBooksEdit}/:managementBookId`}>
      <Layout>
        <Suspense fallback={<Loader />}>
          <EditManagementBookPage />
        </Suspense>
      </Layout>
    </PrivateRoute>
    <PrivateRoute exact path={RoutesEnum.EntityChangeLog}>
      <Layout>
        <Suspense fallback={<Loader />}>
          <EntityChangeLogPage />
        </Suspense>
      </Layout>
    </PrivateRoute>
    <PrivateRoute exact path={`${RoutesEnum.EntityChangeLogView}/:entityId`}>
      <Layout>
        <Suspense fallback={<Loader />}>
          <EntityChangeLogViewPage />
        </Suspense>
      </Layout>
    </PrivateRoute>
    <PrivateRoute exact path={RoutesEnum.EntityChangeLogPropertiesList}>
      <Layout>
        <Suspense fallback={<Loader />}>
          <EntityChangeLogPropertiesListPage />
        </Suspense>
      </Layout>
    </PrivateRoute>
    <PrivateRoute exact path={RoutesEnum.AdministrativeCases}>
      <Layout>
        <Suspense fallback={<Loader />}>
          <AdministrativeCasesPage />
        </Suspense>
      </Layout>
    </PrivateRoute>
    <PrivateRoute exact path={RoutesEnum.AssignedAdministrativeCases}>
      <Layout>
        <Suspense fallback={<Loader />}>
          <AdministrativeCasesPage getOnlyAssignedCases />
        </Suspense>
      </Layout>
    </PrivateRoute>
    <PrivateRoute exact path={RoutesEnum.AdministrativeCasesAssignedToDepartment}>
      <Layout>
        <Suspense fallback={<Loader />}>
          <AdministrativeCasesAssignedToDepartment />
        </Suspense>
      </Layout>
    </PrivateRoute>
    <PrivateRoute exact path={RoutesEnum.ManagementBooks}>
      <Layout>
        <Suspense fallback={<Loader />}>
          <ManagementBookPage />
        </Suspense>
      </Layout>
    </PrivateRoute>
    <PrivateRoute exact path={`${RoutesEnum.AdministrativeCasesEdit}/:administrativeCaseId`}>
      <Layout>
        <Suspense fallback={<Loader />}>
          <EditAdministrativeCasePage />
        </Suspense>
      </Layout>
    </PrivateRoute>
    <PrivateRoute exact path={RoutesEnum.AdministrativeCasesCreate}>
      <Layout>
        <Suspense fallback={<Loader />}>
          <CreateAdministrativeCasePage />
        </Suspense>
      </Layout>
    </PrivateRoute>
    <PrivateRoute exact path={`${RoutesEnum.AdministrativeCasesView}/:administrativeCaseId`}>
      <Layout>
        <Suspense fallback={<Loader />}>
          <ViewAdministrativeCasePage />
        </Suspense>
      </Layout>
    </PrivateRoute>
    <PrivateRoute exact path={`${RoutesEnum.AdministrativeCasesAssign}`}>
      <Layout>
        <Suspense fallback={<Loader />}>
          <AssignAdministrativeCasesPage />
        </Suspense>
      </Layout>
    </PrivateRoute>
    <PrivateRoute exact path={RoutesEnum.AdministrativeCasesPlanner}>
      <Layout>
        <Suspense fallback={<Loader />}>
          <AdministrativeCasesPlannerPage />
        </Suspense>
      </Layout>
    </PrivateRoute>
    <PrivateRoute exact path={`${RoutesEnum.AdministrativeCaseDocumentEdit}/:administrativeCaseId`}>
      <Layout>
        <Suspense fallback={<Loader />}>
          <EditAdministrativeCaseDocumentPage />
        </Suspense>
      </Layout>
    </PrivateRoute>
    <PrivateRoute
      exact
      path={[
        RoutesEnum.AdministrativeCaseDocumentCreate,
        `${RoutesEnum.AdministrativeCaseDocumentCreateFrom}/:administrativeCaseId`,
      ]}
    >
      <Layout>
        <Suspense fallback={<Loader />}>
          <CreateAdministrativeCaseDocumentPage />
        </Suspense>
      </Layout>
    </PrivateRoute>
    <PrivateRoute exact path={`${RoutesEnum.AdministrativeCaseArchiveUnits}`}>
      <Layout>
        <Suspense fallback={<Loader />}>
          <AdministrativeCaseArchiveUnitsPage />
        </Suspense>
      </Layout>
    </PrivateRoute>
    <PrivateRoute exact path={`${RoutesEnum.AdministrativeCaseArchiveUnitsCreate}`}>
      <Layout>
        <Suspense fallback={<Loader />}>
          <CreateAdministrativeCaseArchiveUnitPage />
        </Suspense>
      </Layout>
    </PrivateRoute>
    <PrivateRoute exact path={`${RoutesEnum.AdministrativeCaseArchiveUnitsEdit}/:archiveUnitId`}>
      <Layout>
        <Suspense fallback={<Loader />}>
          <EditAdministrativeCaseArchiveUnitPage />
        </Suspense>
      </Layout>
    </PrivateRoute>
    <PrivateRoute exact path={`${RoutesEnum.AdministrativeCaseArchiveUnitsView}/:archiveUnitId`}>
      <Layout>
        <Suspense fallback={<Loader />}>
          <ViewAdministrativeCaseArchiveUnitPage />
        </Suspense>
      </Layout>
    </PrivateRoute>
    <PrivateRoute
      exact
      path={`${RoutesEnum.AdministrativeDocumentCreate}/:administrativeCaseId/:administrativeDocumentTypeId`}
    >
      <Layout>
        <Suspense fallback={<Loader />}>
          <CreateAdministrativeDocumentPage />
        </Suspense>
      </Layout>
    </PrivateRoute>
    <PrivateRoute exact path={`${RoutesEnum.AdministrativeDocumentEdit}/:administrativeDocumentId/`}>
      <Layout>
        <Suspense fallback={<Loader />}>
          <EditAdministrativeDocumentPage />
        </Suspense>
      </Layout>
    </PrivateRoute>
    <PrivateRoute exact path={RoutesEnum.AdministrativeDocumentTypes}>
      <Layout>
        <Suspense fallback={<Loader />}>
          <AdministrativeDocumentTypePage />
        </Suspense>
      </Layout>
    </PrivateRoute>
    <PrivateRoute exact path={`${RoutesEnum.AdministrativeDocumentTypeEdit}/:administrativeDocumentTypeId`}>
      <Layout>
        <Suspense fallback={<Loader />}>
          <EditAdministrativeDocumentTypePage />
        </Suspense>
      </Layout>
    </PrivateRoute>
    <PrivateRoute exact path={RoutesEnum.AdministrativeDocumentTypeCreate}>
      <Layout>
        <Suspense fallback={<Loader />}>
          <CreateAdministrativeDocumentTypePage />
        </Suspense>
      </Layout>
    </PrivateRoute>
    <PrivateRoute exact path={RoutesEnum.AdministrativeDocumentsRegistryBook}>
      <Layout>
        <Suspense fallback={<Loader />}>
          <AdministrativeDocumentsRegistryBookPage />
        </Suspense>
      </Layout>
    </PrivateRoute>
    <PrivateRoute exact path={RoutesEnum.ClassificationCodes}>
      <Layout>
        <Suspense fallback={<Loader />}>
          <ClassificationCodesPage />
        </Suspense>
      </Layout>
    </PrivateRoute>
    <PrivateRoute exact path={`${RoutesEnum.ClassificationCodesEdit}/:classificationCodeId`}>
      <Layout>
        <Suspense fallback={<Loader />}>
          <EditClassificationCodePage />
        </Suspense>
      </Layout>
    </PrivateRoute>
    <PrivateRoute
      exact
      path={[RoutesEnum.ClassificationCodesCreate, `${RoutesEnum.ClassificationCodesCreateFrom}/:classificationCodeId`]}
    >
      <Layout>
        <Suspense fallback={<Loader />}>
          <CreateClassificationCodePage />
        </Suspense>
      </Layout>
    </PrivateRoute>
    <PrivateRoute exact path={RoutesEnum.Configuration}>
      <Layout>
        <Suspense fallback={<Loader />}>
          <ConfigurationsDataPage />
        </Suspense>
      </Layout>
    </PrivateRoute>
    <PrivateRoute exact path={RoutesEnum.ContractorClasses}>
      <Layout>
        <Suspense fallback={<Loader />}>
          <ContractorClassPage />
        </Suspense>
      </Layout>
    </PrivateRoute>
    <PrivateRoute exact path={`${RoutesEnum.ContractorClassesEdit}/:contractorClassId`}>
      <Layout>
        <Suspense fallback={<Loader />}>
          <EditContractorClassPage />
        </Suspense>
      </Layout>
    </PrivateRoute>
    <PrivateRoute exact path={RoutesEnum.ContractorClassesCreate}>
      <Layout>
        <Suspense fallback={<Loader />}>
          <CreateContractorClassPage />
        </Suspense>
      </Layout>
    </PrivateRoute>
    <PrivateRoute exact path={RoutesEnum.Contractors}>
      <Layout>
        <Suspense fallback={<Loader />}>
          <ContractorsPage />
        </Suspense>
      </Layout>
    </PrivateRoute>
    <PrivateRoute exact path={`${RoutesEnum.ContractorsEdit}/:contractorId`}>
      <Layout>
        <Suspense fallback={<Loader />}>
          <EditContractorPage />
        </Suspense>
      </Layout>
    </PrivateRoute>
    <PrivateRoute exact path={RoutesEnum.ContractorsCreate}>
      <Layout>
        <Suspense fallback={<Loader />}>
          <CreateContractorPage />
        </Suspense>
      </Layout>
    </PrivateRoute>
    <PrivateRoute exact path={RoutesEnum.AdministrativeCaseArchiveHistories}>
      <Layout>
        <Suspense fallback={<Loader />}>
          <AdministrativeCaseArchiveHistoryPage />
        </Suspense>
      </Layout>
    </PrivateRoute>
    <PrivateRoute exact path={RoutesEnum.AdministrativeCaseArchiveHistoriesCreate}>
      <Layout>
        <Suspense fallback={<Loader />}>
          <CreateAdministrativeCaseArchiveHistoryPage />
        </Suspense>
      </Layout>
    </PrivateRoute>
    <PrivateRoute exact path={`${RoutesEnum.AdministrativeCaseArchiveHistoriesEdit}/:archiveHistoryId`}>
      <Layout>
        <Suspense fallback={<Loader />}>
          <EditAdministrativeCaseArchiveHistoryPage />
        </Suspense>
      </Layout>
    </PrivateRoute>
    <PrivateRoute exact path={RoutesEnum.DocumentHeaders}>
      <Layout>
        <Suspense fallback={<Loader />}>
          <DocumentHeadersPage />
        </Suspense>
      </Layout>
    </PrivateRoute>
    <PrivateRoute exact path={`${RoutesEnum.DocumentHeadersEdit}/:documentHeaderId`}>
      <Layout>
        <Suspense fallback={<Loader />}>
          <EditDocumentHeaderPage />
        </Suspense>
      </Layout>
    </PrivateRoute>
    <PrivateRoute exact path={RoutesEnum.DocumentHeadersCreate}>
      <Layout>
        <Suspense fallback={<Loader />}>
          <CreateDocumentHeaderPage />
        </Suspense>
      </Layout>
    </PrivateRoute>
    <PrivateRoute exact path={RoutesEnum.DocumentSign}>
      <Layout>
        <Suspense fallback={<Loader />}>
          <SigningDocumentHeadersPage />
        </Suspense>
      </Layout>
    </PrivateRoute>
    <PrivateRoute exact path={RoutesEnum.Departments}>
      <Layout>
        <Suspense fallback={<Loader />}>
          <DepartmentsPage />
        </Suspense>
      </Layout>
    </PrivateRoute>
    <PrivateRoute exact path={`${RoutesEnum.DepartmentsEdit}/:departmentId`}>
      <Layout>
        <Suspense fallback={<Loader />}>
          <EditDepartmentPage />
        </Suspense>
      </Layout>
    </PrivateRoute>
    <PrivateRoute exact path={RoutesEnum.DepartmentsCreate}>
      <Layout>
        <Suspense fallback={<Loader />}>
          <CreateDepartmentPage />
        </Suspense>
      </Layout>
    </PrivateRoute>
    <PrivateRoute exact path={RoutesEnum.OfficialNoteTexts}>
      <Layout>
        <Suspense fallback={<Loader />}>
          <OfficialNoteTextPage />
        </Suspense>
      </Layout>
    </PrivateRoute>
    <PrivateRoute exact path={`${RoutesEnum.OfficialNoteTextsEdit}/:officialNoteTextId`}>
      <Layout>
        <Suspense fallback={<Loader />}>
          <EditOfficialNoteTextPage />
        </Suspense>
      </Layout>
    </PrivateRoute>
    <PrivateRoute exact path={RoutesEnum.OfficialNoteTextsCreate}>
      <Layout>
        <Suspense fallback={<Loader />}>
          <CreateOfficialNoteTextPage />
        </Suspense>
      </Layout>
    </PrivateRoute>
    <PrivateRoute exact path={RoutesEnum.NkdClasses}>
      <Layout>
        <Suspense fallback={<Loader />}>
          <NkdClassesPage />
        </Suspense>
      </Layout>
    </PrivateRoute>
    <PrivateRoute exact path={`${RoutesEnum.NkdClassesEdit}/:nkdClassId`}>
      <Layout>
        <Suspense fallback={<Loader />}>
          <EditNkdClassPage />
        </Suspense>
      </Layout>
    </PrivateRoute>
    <PrivateRoute exact path={[RoutesEnum.NkdClassesCreate, `${RoutesEnum.NkdClassesCreateFrom}/:nkdClassId`]}>
      <Layout>
        <Suspense fallback={<Loader />}>
          <CreateNkdClassPage />
        </Suspense>
      </Layout>
    </PrivateRoute>
    <PrivateRoute exact path={[RoutesEnum.UserOptions, `${RoutesEnum.UserOptionsGet}/:userId`]}>
      <Layout>
        <Suspense fallback={<Loader />}>
          <UserOptionsPage />
        </Suspense>
      </Layout>
    </PrivateRoute>
    <PrivateRoute exact path={RoutesEnum.Users}>
      <Layout>
        <Suspense fallback={<Loader />}>
          <UsersPage />
        </Suspense>
      </Layout>
    </PrivateRoute>
    <PrivateRoute exact path={`${RoutesEnum.UsersEdit}/:id`}>
      <Layout>
        <Suspense fallback={<Loader />}>
          <EditUserPage />
        </Suspense>
      </Layout>
    </PrivateRoute>
    <PrivateRoute exact path={RoutesEnum.UsersCreate}>
      <Layout>
        <Suspense fallback={<Loader />}>
          <CreateUserPage />
        </Suspense>
      </Layout>
    </PrivateRoute>
    <PrivateRoute exact path={`${RoutesEnum.ChangePassword}`}>
      <Layout>
        <Suspense fallback={<Loader />}>
          <ChangePasswordPage />
        </Suspense>
      </Layout>
    </PrivateRoute>
    <PrivateRoute exact path={RoutesEnum.UserTokens}>
      <Layout>
        <Suspense fallback={<Loader />}>
          <UserTokensPage />
        </Suspense>
      </Layout>
    </PrivateRoute>
    <PrivateRoute exact path={RoutesEnum.OrderForms}>
      <Layout>
        <Suspense fallback={<Loader />}>
          <OrderFormsPage />
        </Suspense>
      </Layout>
    </PrivateRoute>
    <Route exact path={`${RoutesEnum.AdministrativeDocumentPublicView}`}>
      <Suspense fallback={<Loader />}>
        <AdministrativeDocumentPublicView />
      </Suspense>
    </Route>
    <Route exact path={`${RoutesEnum.AdministrativeDocumentPublicSearch}/:uniqueId?`}>
      <Suspense fallback={<Loader />}>
        <AdministrativeDocumentPublicSearch />
      </Suspense>
    </Route>
  </Switch>
);

export default Router;
