export enum UserOptionsKeyEnum {
  StartPage = 1,
  AllowAdministrativeDocumentsDelete = 2,
  AllowAdministrativeDocumentDateCreatedChange = 3,
  AllowOnlyAssignedAdministrativeCasesView = 4,
  AllowReturnAdministrativeCaseFromArchive = 5,
  UserAccessRights = 6,
  AdministrativeDocumentRestrictionsByType = 7,
  AllowDocumentsDelete = 8,
  AllowAssignedUserChange = 9,
  AllowTokenCreate = 10,
  AllowTokenDeactivate = 11,
  AllowTokenReactivate = 12,
  AllowAdministrativeCaseChangeStatusAndAssignedUser = 13,
  AllowAdministrativeCaseDocumentCopy = 14,
}

export default UserOptionsKeyEnum;
