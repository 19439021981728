import { CCol, CContainer, CRow, CSpinner } from "@coreui/react";
import React from "react";

interface LoaderProps {
  spinnerSize?: string | undefined;
  isGrowSpinner?: boolean | undefined;
  spinnerColor?: string | undefined;
}

const Loader: React.FC<LoaderProps> = (props?) => {
  const { spinnerSize, isGrowSpinner, spinnerColor } = props;

  return (
    <CContainer fluid className="w-100 h-100 p-2 m-0">
      <CRow>
        <CCol xs="12" className="text-center align-middle">
          <CSpinner size={spinnerSize ?? "xl"} grow={isGrowSpinner ?? true} color={spinnerColor ?? "primary"} />
        </CCol>
      </CRow>
    </CContainer>
  );
};

export default Loader;
