import React, { useCallback, useEffect, useState } from "react";
import { RouteProps, Route, Redirect } from "react-router-dom";
import { useSelector } from "react-redux";
import { selectIsAuthenticated, selectUser, selectUserRights } from "store/userSlice";
import userOptionsApi from "api/userOptionsApi";
import UserOptionsKeyEnum from "constants/UserOptionsKeyEnum";
import { authorizedRoutes, RoutesEnum } from "constants/PageRoutes";
import { isNaN } from "lodash";

const PrivateRoute: React.FC<RouteProps> = ({ children, ...rest }) => {
  const [firstPage, setFirstPage] = useState<string>("/");
  const isAuthenticated = useSelector(selectIsAuthenticated);
  const currentUser = useSelector(selectUser);
  const userRights = useSelector(selectUserRights);
  const [ignoreList] = useState<Array<string>>([
    RoutesEnum.BlankPage,
    RoutesEnum.PageUserNotAuthorized,
    RoutesEnum.PageNotFound,
    RoutesEnum.LogIn,
  ]);

  const hasRight = useCallback(
    (routePath?: string): boolean => {
      let result = true;
      if (!routePath) {
        result = false;
      }

      if (ignoreList.includes(routePath!)) return true;
      let exactRoutePath: string | undefined = routePath;
      if (result) {
        const routeParts = routePath?.split("/").filter((item: string) => item !== "");
        if ((routeParts?.length || 0) > 1) {
          exactRoutePath = routeParts?.reduce((acc: string, curr: string): string => {
            const currentNumber = parseInt(curr, 10);
            if (isNaN(currentNumber)) {
              acc += `/${curr}`;
            }
            return acc;
          }, "");
        }
      }

      if (result && (!exactRoutePath || ignoreList.includes(exactRoutePath))) {
        result = false;
      }

      if (result) {
        const routeKey = authorizedRoutes.find((item) => item.routePath === exactRoutePath)?.key;
        if (!routeKey) {
          result = false;
        } else {
          result = (userRights?.filter((item) => item.optionItem === routeKey && item.selected).length || 0) > 0;
        }
      }
      return result;
    },
    [ignoreList, userRights],
  );

  const fetchFirstPage = useCallback(async () => {
    const response = await userOptionsApi.getUserOption(currentUser?.id || 0, UserOptionsKeyEnum.StartPage);
    if (response) {
      if (hasRight(response.value)) {
        setFirstPage(response.value);
      }
    }
  }, [currentUser?.id, hasRight]);

  useEffect(() => {
    fetchFirstPage();
  }, [fetchFirstPage]);

  if (!isAuthenticated) {
    return <Redirect to={RoutesEnum.LogIn} />;
  }
  if (!!rest.location && rest.location?.pathname === RoutesEnum.BlankPage) {
    if (firstPage !== RoutesEnum.BlankPage) {
      if (!hasRight(firstPage)) {
        setFirstPage(RoutesEnum.BlankPage);
        return <Redirect to={RoutesEnum.BlankPage} />;
      }
      return <Redirect to={firstPage} />;
    }
  }
  if (!hasRight(rest.location?.pathname)) return <Redirect to={RoutesEnum.PageUserNotAuthorized} />;
  return <Route {...rest}>{children}</Route>;
};

export default PrivateRoute;
