import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import authApi from "api/authApi";
import { addAuthorization, removeAuthorization } from "api/http";
import userOptionsApi from "api/userOptionsApi";
import usersApi from "api/usersApi";
import { UserDto, UserOptionDto } from "models";
import { LoginRequest } from "models/requests";
import { LoginResponse } from "models/responses";
import { Toast } from "models/Toast";
import { RootState } from "store";

interface UserState {
  token?: string;
  user?: UserDto;
  isSidebarOpen: boolean | "responsive";
  toasts: Toast[];
  userOption?: UserOptionDto;
}

const initialState: UserState = {
  token: undefined,
  user: undefined,
  isSidebarOpen: "responsive",
  toasts: [],
  userOption: undefined,
};

/** ASYNC THUNKS */
export const initializeAsync = createAsyncThunk<string | null>(
  "user/initializeAsync",
  async (_, { rejectWithValue, dispatch }) => {
    try {
      const token = localStorage.getItem("@zuc:token");
      if (token) {
        addAuthorization(token);
        const resultAction = await dispatch(getMeAsync());
        if (getMeAsync.fulfilled.match(resultAction)) {
          const resultOption = await dispatch(getMyRights());
          if (getMyRights.fulfilled.match(resultOption)) {
            setUserOption(resultOption.payload);
          }
          return token;
        }
      }
      return null;
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response.data);
    }
  },
);

export const loginAsync = createAsyncThunk<LoginResponse, LoginRequest>(
  "user/loginAsync",
  async (request, { rejectWithValue, dispatch }) => {
    try {
      const response = await authApi.login(request);
      addAuthorization(response.token);
      localStorage.setItem("@zuc:token", response.token);
      await dispatch(getMeAsync());
      const resultOption = await dispatch(getMyRights());
      if (getMyRights.fulfilled.match(resultOption)) {
        setUserOption(resultOption.payload);
      }
      return response;
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response.data);
    }
  },
);

export const getMeAsync = createAsyncThunk<UserDto>("user/getMeAsync", async (_, { rejectWithValue }) => {
  try {
    const response = await usersApi.getMe();
    return response;
  } catch (err) {
    if (!err.response) {
      throw err;
    }
    return rejectWithValue(err.response.data);
  }
});

export const getMyRights = createAsyncThunk<UserOptionDto>("user/getMyRights", async (_, { rejectWithValue }) => {
  try {
    const response = await userOptionsApi.getMyRights();
    return response;
  } catch (err) {
    if (!err.response) {
      throw err;
    }
    return rejectWithValue(err.response.data);
  }
});

export const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    logout: (state) => {
      state.token = undefined;
      state.user = undefined;
      state.userOption = undefined;
      removeAuthorization();
      localStorage.removeItem("@zuc:token");
    },
    setUser: (state, action: PayloadAction<UserDto>) => {
      state.user = action.payload;
    },
    toggleSidebar: (state, action: PayloadAction<boolean | "responsive">) => {
      state.isSidebarOpen = action.payload;
    },
    addToast: (state, action: PayloadAction<Toast>) => {
      state.toasts = [...state.toasts, action.payload];
    },
    addSuccessToast: (state, action: PayloadAction<string>) => {
      state.toasts = [...state.toasts, { text: action.payload, type: "success" }];
    },
    addErrorToast: (state, action: PayloadAction<string>) => {
      state.toasts = [...state.toasts, { text: action.payload, type: "danger" }];
    },
    setUserOption: (state, action: PayloadAction<UserOptionDto>) => {
      state.userOption = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(initializeAsync.fulfilled, (state, action) => {
        const token = action.payload;
        if (token) {
          state.token = token;
        }
      })
      .addCase(loginAsync.fulfilled, (state, action) => {
        const { token } = action.payload;
        state.token = token;
      })
      .addCase(getMeAsync.fulfilled, (state, action) => {
        const user = action.payload;
        state.user = user;
      })
      .addCase(getMyRights.fulfilled, (state, action) => {
        const userOption = action.payload;
        state.userOption = userOption;
      });
  },
});

export const { logout, setUser, toggleSidebar, addToast, addSuccessToast, addErrorToast, setUserOption } =
  userSlice.actions;

export const selectIsAuthenticated = (state: RootState): boolean => !!state.user.token;
export const selectIsSidebarOpen = (state: RootState): boolean | "responsive" => state.user.isSidebarOpen;
export const selectInitials = (state: RootState): string => {
  return `${state.user?.user?.firstName[0]}${state.user?.user?.lastName[0]}`;
};
export const selectUser = (state: RootState): UserDto | undefined => state.user?.user;
export const selectToasts = (state: RootState): Toast[] => state.user.toasts;
export const selectUserRights = (state: RootState): Array<{ optionItem: string; selected: boolean }> | undefined => {
  if (!state.user.userOption?.value) return undefined;
  try {
    const response: Array<{ optionItem: string; selected: boolean }> = JSON.parse(state.user.userOption?.value);
    return response;
  } catch (e) {
    return undefined;
  }
};

export default userSlice.reducer;
