import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "store";

interface LoadingState {
  isLoading: number[];
  isSaving: number[];
}

const initialState: LoadingState = {
  isLoading: [],
  isSaving: [],
};

export const loading = createSlice({
  name: "loading",
  initialState,
  reducers: {
    loadingOn: (state) => {
      state.isLoading.push(1);
    },
    loadingOff: (state) => {
      state.isLoading.pop();
    },
    savingOn: (state) => {
      state.isSaving.push(1);
    },
    savingOff: (state) => {
      state.isSaving.pop();
    },
  },
});

export const { loadingOn, loadingOff, savingOn, savingOff } = loading.actions;
export const selectIsLoading = (state: RootState): boolean => state.loading.isLoading.length > 0;
export const selectIsSaving = (state: RootState): boolean => state.loading.isSaving.length > 0;

export default loading.reducer;
