interface ObjectConstructor {
  enumKeys(x: unknown): string[];
  enumValues(x: unknown): number[];
}

Object.enumKeys = (x: unknown): string[] => {
  return Object.keys(x).filter((key) => Number.isNaN(parseInt(key, 10)));
};

Object.enumValues = (x: unknown): number[] => {
  return Object.values(x).filter((value) => Number.isInteger(value)) as number[];
};
