import loggerApi from "api/loggerApi";
import ConfigurationKeyEnum from "constants/ConfigurationKey";
import { ConfigurationDataDto } from "models";
import { CreateConfigurationDataRequest, EditConfigurationDataRequest } from "models/requests";
import http from "./http";

const baseRoute = "configuration-data";
const configurationDataApi = {
  async getConfiguration(key?: string): Promise<ConfigurationDataDto> {
    try {
      const response = await http.get<ConfigurationDataDto>(`${baseRoute}/get/${key}`);
      return response.data;
    } catch (e) {
      loggerApi.log({
        level: "error",
        message: "getConfiguration.getConfiguration",
        error: e.message || "",
      });
      throw e;
    }
  },

  async getConfigurations(): Promise<Array<ConfigurationDataDto>> {
    try {
      const response = await http.get<Array<ConfigurationDataDto>>(`${baseRoute}/get-all`);
      return response.data;
    } catch (e) {
      loggerApi.log({
        level: "error",
        message: "getConfiguration.getConfigurations",
        error: e.message || "",
      });
      throw e;
    }
  },

  async update(key: string, request: EditConfigurationDataRequest): Promise<ConfigurationDataDto> {
    try {
      const response = await http.post<ConfigurationDataDto>(`${baseRoute}/update/${key}`, request);
      return response.data;
    } catch (e) {
      loggerApi.log({
        level: "error",
        message: "getConfiguration.update",
        error: e.message || "",
      });
      throw e;
    }
  },

  async updateAll(request: Array<CreateConfigurationDataRequest>): Promise<Array<ConfigurationDataDto>> {
    try {
      const response = await http.post<Array<ConfigurationDataDto>>(`${baseRoute}/update-all`, request);
      return response.data;
    } catch (e) {
      loggerApi.log({
        level: "error",
        message: "getConfiguration.updateAll",
        error: e.message || "",
      });
      throw e;
    }
  },

  async create(request: CreateConfigurationDataRequest): Promise<ConfigurationDataDto> {
    try {
      const response = await http.post<ConfigurationDataDto>(`${baseRoute}/create`, request);
      return response.data;
    } catch (e) {
      loggerApi.log({
        level: "error",
        message: "getConfiguration.create",
        error: e.message || "",
      });
      throw e;
    }
  },

  async getAutoAssignCode(): Promise<boolean> {
    try {
      const response = await this.getConfiguration(ConfigurationKeyEnum.AutoAssignContractorCode.toString());
      return response && response.value === "true";
    } catch (e) {
      loggerApi.log({
        level: "error",
        message: "getConfiguration.getAutoAssignCode",
        error: e.message || "",
      });
      throw e;
    }
  },
};

export default configurationDataApi;
