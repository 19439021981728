enum ConfigurationKeyEnum {
  Undefined = -1,
  RegionCode = 1,
  RegionName = 2,
  OrganizationCode = 3,
  OrganizationName = 4,
  AllowInsertNDaysBefore = 5,
  NotifyNDaysBefore = 6,
  // ActivateNDaysBefore = 7,
  // ActiveScanner = 8,
  // ActiveBarcodePrinter = 9,
  AutoAssignContractorCode = 10,
  UseContractorClassCodeAsURNum = 11,
  // MaxUploadFileSize = 12,
  AllowDeleteOnAllAdministrativeItems = 13,
  NotifyOnAdministrativeCaseDeadline = 14,
  NotifyOnAdministrativeCaseFirstAppealDate = 15,
  NotifyOnAdministrativeCaseSecondAppealDate = 16,
  SearchAndLinkDocumentDaysBefore = 17,
  ManagementBooks = 19,
  OneCaseOneDocument = 20,
  NotifyOnAdministrativeDocumentRecieved = 21,
  DocumentRegistryNumberFormat = 22,
  DocumentRegistryNumberAlwaysVisible = 23,
  AdministrativeDocumentNameAsDocumentFileName = 24,
  OrderForms = 25,
  OrganizationVAT = 26,
  AdministrativeCaseAssignToDepartment = 27,
}

export const configurationKeyTranslations: Record<ConfigurationKeyEnum, string> = {
  [ConfigurationKeyEnum.Undefined]: "",
  [ConfigurationKeyEnum.RegionCode]: "Županija",
  [ConfigurationKeyEnum.RegionName]: "",
  [ConfigurationKeyEnum.OrganizationCode]: "Organizacija",
  [ConfigurationKeyEnum.OrganizationName]: "",
  [ConfigurationKeyEnum.OrganizationVAT]: "",
  [ConfigurationKeyEnum.AllowInsertNDaysBefore]: "",
  [ConfigurationKeyEnum.NotifyNDaysBefore]: "",
  [ConfigurationKeyEnum.AutoAssignContractorCode]: "",
  [ConfigurationKeyEnum.UseContractorClassCodeAsURNum]: "",
  [ConfigurationKeyEnum.AllowDeleteOnAllAdministrativeItems]: "",
  [ConfigurationKeyEnum.NotifyOnAdministrativeCaseDeadline]: "",
  [ConfigurationKeyEnum.NotifyOnAdministrativeCaseFirstAppealDate]: "",
  [ConfigurationKeyEnum.NotifyOnAdministrativeCaseSecondAppealDate]: "",
  [ConfigurationKeyEnum.SearchAndLinkDocumentDaysBefore]: "",
  [ConfigurationKeyEnum.ManagementBooks]: "",
  [ConfigurationKeyEnum.OneCaseOneDocument]: "",
  [ConfigurationKeyEnum.NotifyOnAdministrativeDocumentRecieved]: "",
  [ConfigurationKeyEnum.DocumentRegistryNumberFormat]: "",
  [ConfigurationKeyEnum.DocumentRegistryNumberAlwaysVisible]: "",
  [ConfigurationKeyEnum.AdministrativeDocumentNameAsDocumentFileName]: "",
  [ConfigurationKeyEnum.OrderForms]: "",
  [ConfigurationKeyEnum.AdministrativeCaseAssignToDepartment]: "",
};

export default ConfigurationKeyEnum;
