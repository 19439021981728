import React from "react";
import { render } from "react-dom";
import { BrowserRouter } from "react-router-dom";
import { Provider } from "react-redux";
import Toaster from "components/shared/Toaster";
import "fontsource-roboto";
import store from "store";
import App from "./App";
import "./extensions.d";

render(
  <BrowserRouter>
    <Provider store={store}>
      <Toaster />
      <App />
    </Provider>
  </BrowserRouter>,
  document.getElementById("root"),
);
