import { useCallback, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";

import { ConfigurationDataDto } from "models";
import { RoutesEnum } from "constants/PageRoutes";

import configurationDataApi from "api/configurationDataApi";

import { useDispatch } from "react-redux";
import { loadingOff, loadingOn } from "store/loadingSlice";
import { addToast } from "store/userSlice";

import useIsMounted from "./useIsMounted";

type Configurations = {
  configurations?: ConfigurationDataDto[];
};

export function useConfigurations(): Configurations {
  const [configurations, setConfigurationData] = useState<ConfigurationDataDto[]>([]);
  const isMounted = useIsMounted();
  const history = useHistory();
  const dispatch = useDispatch();

  const fetchConfigurations = useCallback(async (): Promise<ConfigurationDataDto[]> => {
    const data = await configurationDataApi.getConfigurations();
    return isMounted() ? data : [];
  }, [isMounted]);

  useEffect(() => {
    (async () => {
      dispatch(loadingOn());
      await fetchConfigurations()
        .then((data) => setConfigurationData(data))
        .catch((err) => {
          dispatch(addToast({ text: err.message || "", type: "danger" }));
          history.replace(RoutesEnum.PageNotFound);
        })
        .finally(() => dispatch(loadingOff()));
    })();
  }, [fetchConfigurations, dispatch, history]);

  return {
    configurations,
  };
}
