import { BrandingDto } from "models";
import http from "./http";

const baseRoute = "branding";
const brandingApi = {
  async getBranding(): Promise<BrandingDto> {
    const response = await http.get<BrandingDto>(`${baseRoute}`);
    return response.data;
  },
};

export default brandingApi;
