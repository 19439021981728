/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-template-curly-in-string */
import { setLocale } from "yup";
import "yup/lib/locale";

setLocale({
  mixed: {
    default: "${path} je neispranog oblika",
    required: "${path} je obavezno polje",
    oneOf: "${path} mora biti jedna od sljedećih vrijednosti: ${values}",
    notOneOf: "${path} ne smije biti jedna od sljedećih vrijednosti: ${values}",
    defined: "${path} mora biti definiran",
    notType: (args: any) => {
      let type = "";
      switch (args.type) {
        case "number":
          type = "broj";
          break;
        default:
          return `${args.path} je neispravnog tipa`;
      }
      return `${args.path} mora biti ${type}`;
    },
  },
  string: {
    length: "${path} mora biti dužine ${length} znakova",
    min: "${path} mora biti minimalne dužine od ${min} znakova",
    max: "${path} mora biti maksimalne dužine od ${max} znakova",
    matches: '${path} mora odgovarati sljedećem: "${regex}"',
    email: "${path} mora biti valjana adresa e -pošte",
    url: "${path} mora biti važeći URL",
    uuid: "${path} mora biti važeći UUID",
    trim: "${path} mora biti obrezan tekst",
    lowercase: "${path} mora sadržavati samo mala slova",
    uppercase: "${path} mora sadržavati samo velika slova",
  },
  number: {
    min: "${path} mora biti veći ili jednak ${min}",
    max: "${path} mora biti manji ili jednak ${max}",
    lessThan: "${path} mora biti manji od ${less}",
    moreThan: "${path} mora biti veći od ${more}",
    positive: "${path} mora biti pozitivan broj",
    negative: "${path} mora biti negativan broj",
    integer: "${path} mora biti cijeli broj",
  },
  date: {
    min: "${path} mora biti poslije ${min}",
    max: "${path} mora biti prije ${max}",
  },
  array: {
    min: "${path} mora sadržavati minimalno ${min} stavki",
    max: "${path} mora sadržavati manje ili jednako ${max} stavki",
    length: "${path} mora sadržavati ${length} stavki",
  },
});
