import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import brandingApi from "api/brandingApi";
import { BrandingDto } from "models";
import { RootState } from "store";

interface BrandingState {
  logo?: string;
}

const initialState: BrandingState = {
  logo: "",
};

/** ASYNC THUNKS */
export const initializeBrandingAsync = createAsyncThunk("branding/initializeBrandingAsync", async (_, { dispatch }) => {
  try {
    await dispatch(getBrandingAsync());
  } catch (err) {
    if (!err.response) {
      throw err;
    }
  }
});

export const getBrandingAsync = createAsyncThunk<BrandingDto>("branding", async (_, { rejectWithValue }) => {
  try {
    return await brandingApi.getBranding();
  } catch (err) {
    if (!err.response) {
      throw err;
    }
    return rejectWithValue(err.response.data);
  }
});

export const brandingSlice = createSlice({
  name: "branding",
  initialState,
  reducers: {
    setLogo: (state, action: PayloadAction<string>) => {
      state.logo = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getBrandingAsync.fulfilled, (state, action) => {
      const { logo } = action.payload;
      state.logo = logo;
    });
  },
});
export const { setLogo } = brandingSlice.actions;
export const selectLogo = (state: RootState): string | undefined => state.branding.logo;

export default brandingSlice.reducer;
